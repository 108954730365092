<template>
    <footer>
        <div v-if="showContent" class="footer-info">
            &copy; {{ copyrightDate }} Great Awakening Project &bull; Help Desk
        </div>
    </footer>
</template>

<script>
export default {
    name: 'gap-footer',
    props: {
        userName: String,
        userTypeCode: String
    },
    data() {
        return {
            navigationAllowed: false,
            copyrightDate: ''
        }
    },
    created() {
        let today = new Date();
        this.copyrightDate = today.getFullYear();
    },
    computed: {
        showContent() {
            return this.userName && this.$route.path.indexOf('login') < 0
        }
    }
}
</script>
