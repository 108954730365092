<template>
    <div :class="['message-component', { block: block }, { hidden: hidden }, { error: error } ]">{{ localMessage }}</div>
</template>

<script>
export default {
    name: 'message',
    props: {
        message: {
            type: String,
            default: ''
        },
        loading: {
            type: [String, Boolean],
            default: false
        },
        delay: {
            type: [String, Number],
            default: 0
        },
        block: {
            type: [String, Boolean],
            default: false
        },
        error: {
            type: [String, Boolean],
            default: false
        }
    },
    watch: {
        message: function(value) {
            this.localMessage = value
            setTimeout(() => this.localMessage = '', this.delay * 1000)
        }
    },
    data() {
        return {
            localMessage: '',
            hidden: false
        }
    },
    created() {
        this.localMessage = this.message
        if (this.delay > 0 && !this.error) {
            setTimeout(() => this.hidden = true, this.delay * 1000)
        }
    }
}
</script>

<style lang="scss" scoped>
.message-component {
    display: inline-block;
    padding: 0;
    transition: opacity .5s
}
.block {
    display: block;
    padding: 0.5em 0;
}
.hidden {
    opacity: 0;
}
</style>
