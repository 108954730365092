<template>
    <div class="main-content login">
        <div class="login-brand">
            <img src="@/assets/logo-full-dark.png" class="login-screen-logo">
        </div>
        <div class="login-form-wrapper">
            <form class="login-form">
                <label></label>
                <h2>Please Sign In</h2>
                <label>Username</label>
                <input type="text" v-model="user_name">

                <template v-if="showMainPassword">
                    <label>Password</label>
                    <PasswordInput v-model="password" @keyup.enter="doLogin()" />
                    <label></label>
                    <div class="button" @click="doLogin()"><LabelWithLoadingIcon :loading="loading" label="Sign In"/></div>
                    <label></label>
                    <div class="forgot-password-link" @click="showMainPassword = false; showForgotPasswordControls = true">Forgot your password?</div>
                </template>

        		<template v-if="showForgotPasswordControls">
                    <label></label>
        			<div class="note">Enter your username to receive a new temporary password with instructions for logging in again.<br><br></div>
                    <label></label>
        			<div class="button" @click="doPasswordReset()"><LabelWithLoadingIcon :loading="loading" label="Send Temporary Password"/></div>
                    <label></label>
                    <div class="forgot-password-link" @click="showMainPassword = true; showForgotPasswordControls = false">Cancel forgot password</div>
        		</template>

        		<template v-if="showTemporaryPasswordControls">
        			<label>Temporary Password:</label>
                    <PasswordInput v-model="temporaryPassword" @keyup.enter="validateTemporaryPassword()" />
                    <label></label>
        			<div class="note">Check your email for your new temporary password and enter it above.</div>
                    <label></label>
        			<div class="button" @click="validateTemporaryPassword()"><LabelWithLoadingIcon :loading="loading" label="Validate Temporary Password"/></div>
        		</template>

        		<template v-if="showResetPassword">
        			<label class="label">Enter a New Password:</label>
                    <PasswordInput v-model="password"/>
                    <label class="label">Retype New Password:</label>
                    <PasswordInput v-model="retype_password" @keyup.enter="doSetNewPassword()" />
                    <label></label>
        			<div class="note">Your password has expired. Please enter a new permanent password.</div>
                    <label></label>
        			<div class="button" @click="doSetNewPassword()"><LabelWithLoadingIcon :loading="loading" label="Reset Password"/></div>
        		</template>

        		<template v-if="resetPasswordSuccess">
                    <label></label>
        			<div class="note">Your password has been reset!</div>
                    <label></label>
        			<div class="button" @click="goDashboard()">Proceed to Dashboard</div>
        		</template>

                <label></label>
                <Message :message="message" :error="errorStatus" delay="3" :key="message" />
            </form>

        </div>
    </div>
</template>

<script>
import LabelWithLoadingIcon from '@/components/LabelWithLoadingIcon.vue'
import PasswordInput from '@/components/PasswordInput.vue'
import Message from '@/components/Message.vue'
import { mapActions } from 'vuex'
export default {
    name: 'Login',
    components: {
        LabelWithLoadingIcon,
        PasswordInput,
        Message
    },
    data() {
        return {
            loading: false,
            message: '',
            errorStatus: false,
            user_name: '',
            password: '',
            retype_password: '',
            temporaryPassword: '',
            showMainPassword: true,
            showForgotPasswordControls: false,
            showTemporaryPasswordControls: false,
            showResetPassword: false,
            resetPasswordSuccess: false,
            editorContent: ''
        }
    },
    created() {
		if (this.$route.query.logout) {
			this.doLogout()
		}
    },
    computed: {

    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
    	doLogout() {
    		this.apiCall({ destination: 'logout' })
    	},
    	doPasswordReset() {
	    	if (!this.user_name) return
	    	if (this.loading) return
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'forgot_password', data: { user_name: this.user_name, login_url: document.location.protocol + '//' + document.location.hostname }}).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.showForgotPasswordControls = false
					this.showTemporaryPasswordControls = true
    			} else {
					this.message = obj.message
                    this.errorStatus = true
				}
    		})
	    },
		validateTemporaryPassword() {
	    	if (this.loading) return
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'validate_temporary_password', data: { user_name: this.user_name, password: this.temporaryPassword }}).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.showForgotPasswordControls = false
					this.showTemporaryPasswordControls = false
					this.showResetPassword = true
    			} else {
					this.message = 'Oops! ' + obj.message
                    this.errorStatus = true
				}
    		})
		},
	    doSetNewPassword() {
	    	if (this.loading) return
		    this.message = ''
            this.errorStatus = false
    		if (!this.retype_password || !this.password) return
    		if (this.retype_password != this.password) {
	    		this.message = 'Passwords do not match'
                this.errorStatus = true
	    		return
	    	}
		    this.message = ''
			this.loading = true
    		this.apiCall({ destination: 'reset_password', data: { user_name: this.user_name, password: this.password }}).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					this.showResetPassword = false
					this.resetPasswordSuccess = true
    			} else {
	    			this.message = 'Oops! ' + obj.message
                    this.errorStatus = true
    			}
    		})
		},
        goDashboard() {
            this.$router.push('/dashboard')
        },
		doLogin() {
	    	if (this.loading) return
		    this.message = ''
            this.errorStatus = false
			this.loading = true
    		this.apiCall({ destination: 'login', data: { user_name: this.user_name, password: this.password }}).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					if (obj.force_password_change) {
                        this.showMainPassword = false
                        this.showForgotPasswordControls = false
                        this.showResetPassword = true
					} else if (this.$route.query.return) {
						this.$router.push(decodeURI(this.$route.query.return))
					} else if (obj.redirect) {
						this.$router.push('/' + obj.redirect)
					} else if (obj.return) {
						this.$router.push('/' + obj.return)
	    			} else {
	    				this.$router.push('/dashboard')
		    		}
    			} else {
	    			this.message = obj.message
                    this.errorStatus = true
    			}
    		}).catch(err => {
				console.log(err);
			})
		}
    }
}
</script>
<style lang="scss" scoped>
    @import '../styles/_colors.scss';
    div.login {
        background-color: $black;
        text-align: center;
        padding: 0;
    	@media all and (min-width: 980px) {
            text-align: left;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 4em;
            align-items: center;
            padding: 6em 0;
    	}
    	@media all and (min-width: 1400px) {
            grid-template-columns: 1.5fr 1fr;
            padding: 7em 0;
    	}
    }
    .login-brand {
        padding: 0 0 1em 0;
    	@media all and (min-width: 1400px) {
            padding: 3em;
        }
        .login-screen-logo {
            width: 66%;
        	@media all and (min-width: 980px) {
                width: 100%;
        	}
        }
    }
    .login-form-wrapper {
        background-color: white;
    	@media all and (min-width: 980px) {
            padding: 2em 3em 2em 2em;
            border-top-left-radius: 1em;
            border-bottom-left-radius: 1em;
        }
    }
    form.login-form {
        padding: 1em 2em 1em 1em;
        text-align: left;
        h2 { text-align: center; }
    	@media all and (min-width: 980px) {
            padding: 2em 2em 0 0;
            display: grid;
            grid-template-columns: 6em 1fr;
            grid-gap: 1em;
            align-items: center;
        }
        label {
            margin: 0 0 0.5em 0;
            text-align: right;
        }
        .forgot-password-link {
            margin: 1em 0 0 0;
            font-size: 0.8em;
            color: $borders;
        }
        .forgot-password-link:hover {
            color: $buttonColor;
            cursor: pointer;
        }
    }
</style>
