import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'

const routes = [
    {
        path: '/',
        redirect: { name: 'Login' }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
    },
    {
        path: '/user-profile',
        name: 'User Profile',
        // route level code-splitting
        // this generates a separate chunk (user-profile.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "user-profile" */ '../views/UserProfile.vue')
    },
    {
        path: '/web-stats',
        name: 'Web Stats',
        // route level code-splitting
        // this generates a separate chunk (web-stats.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "web-stats" */ '../views/WebStats.vue')
    },
    {
        path: '/contacts',
        name: 'Contacts',
        // route level code-splitting
        // this generates a separate chunk (contacts.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue'),
        children: [
            {
                path: 'detail/:contact_id',
                name: 'Contact Detail',
                component: () => import(/* webpackChunkName: "contact-detail" */ '../views/Contacts.vue'),
                props: true
            }
        ]
    },
    {
        path: '/mailchimp',
        name: 'Mailchimp',
        // route level code-splitting
        // this generates a separate chunk (mailchimp.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "mailchimp" */ '../views/Mailchimp.vue'),
        children: [
            {
                path: 'campaign/:campaign_id',
                name: 'Campaigns Detail',
                component: () => import(/* webpackChunkName: "campaign-detail" */ '../views/Mailchimp.vue'),
                props: true
            },
            {
                path: 'audience/:audience_id',
                name: 'Audience Detail',
                component: () => import(/* webpackChunkName: "campaign-detail" */ '../views/Mailchimp.vue'),
                props: true,
                children: [
                    {
                        path: 'group/:group_id',
                        name: 'Group Detail',
                        component: () => import(/* webpackChunkName: "group-detail" */ '../views/Mailchimp.vue'),
                        props: true
                    }
                ]
            }
        ]
    },
    {
        path: '/users',
        name: 'Users',
        // route level code-splitting
        // this generates a separate chunk (users.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
        children: [
            {
                path: 'detail/:user_id',
                name: 'User Detail',
                component: () => import(/* webpackChunkName: "user-detail" */ '../views/Users.vue'),
                props: true
            }
        ]
    },
    {
        path: '/menus',
        name: 'Manage Menus',
        // route level code-splitting
        // this generates a separate chunk (menus.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "menus" */ '../views/Menus.vue')
    },
    {
        path: '/donations',
        name: 'Donations',
        // route level code-splitting
        // this generates a separate chunk (donations.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "donations" */ '../views/Donations.vue'),
        children: [
            {
                path: 'detail/:donation_id',
                name: 'Donation Detail',
                component: () => import(/* webpackChunkName: "donation-detail" */ '../views/Donations.vue'),
                props: true
            }
        ]
    },
    {
        path: '/registrations',
        name: 'Registrations',
        // route level code-splitting
        // this generates a separate chunk (registrations.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "registrations" */ '../views/Registrations.vue'),
        children: [
            {
                path: 'detail/:registration_id',
                name: 'Registration Detail',
                component: () => import(/* webpackChunkName: "registration-detail" */ '../views/Registrations.vue'),
                props: true
            }
        ]
    },
    {
        path: '/content',
        name: 'Content',
        // route level code-splitting
        // this generates a separate chunk (content.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "content" */ '../views/Content.vue'),
        children: [
            {
                path: 'detail/:content_id',
                name: 'Content Detail',
                component: () => import(/* webpackChunkName: "content-detail" */ '../views/Content.vue'),
                props: true
            }
        ]
    },
    {
        path: '/videos',
        name: 'Videos',
        // route level code-splitting
        // this generates a separate chunk (videos.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "videos" */ '../views/Videos.vue'),
        children: [
            {
                path: 'detail/:video_id',
                name: 'Video Detail',
                component: () => import(/* webpackChunkName: "video-detail" */ '../views/Videos.vue'),
                props: true
            }
        ]
    },
    {
        path: '/images',
        name: 'Images',
        // route level code-splitting
        // this generates a separate chunk (videos.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "images" */ '../views/Images.vue'),
        children: [
            {
                path: 'detail/:image_id',
                name: 'Image Detail',
                component: () => import(/* webpackChunkName: "image-detail" */ '../views/Images.vue'),
                props: true
            }
        ]
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
