<template>
	<div class="password-input">
		<input :type="showPassword ? 'text' : 'password'" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
		<font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" class="icon" @click="showPassword = !showPassword" />
	</div>
</template>
<script>
export default {
	name: 'password-input',
	props: {
        modelValue: {
            type: String,
            default: '',
            required: true
        }
	},
    data() {
        return {
            showPassword: false
        }
    }
}
</script>
<style lang="scss" scoped>
.password-input {
	position: relative;
	.icon {
		position: absolute;
		right: 0;
		top: 0.66em;
		width: 2em;
		text-align: center;
		cursor: pointer;
		color: #333;
	}
}
</style>
