import { createStore } from 'vuex'
import { mapGetters } from 'vuex'

let developmentEnvironment = (document.location.hostname.indexOf('localhost') > -1)
let sandboxEnvironment = (document.location.hostname.indexOf('sandbox') > -1)
let hostname = ''
if (developmentEnvironment) {
    hostname = 'gapapi.test'
} else if (sandboxEnvironment) {
    hostname = 'api.sandbox.gapmovement.com'
} else {
    hostname = 'api.gapmovement.com'
}
let apiURL = 'https://' + hostname + '/api.php'
let downloadURL = 'https://' + hostname + '/download.php'

export default createStore({
    state: {
        source: 'ADMIN',
        apiHostname: hostname,
        apiEndpoint: apiURL,
        downloadEndpoint: downloadURL,
        loading: false,
        loggedIn: false,
        user: {
            user_name: '',
            user_type_code: ''
        }
    },
    mutations: {
        UPDATE_LOADING(state, loading) {
			state.loading = loading
		},
		UPDATE_STATE(state, obj) {
			for (let prop in state) {
                if (Object.prototype.hasOwnProperty.call(obj, prop)) {
					state[prop] = obj[prop]
				}
			}
		},
        UPDATE_USER(state, user) {
			state.user = user
		}
    },
    getters: {
        getApiHostname: state => { return state.apiHostname },
        getApiEndpoint: state => { return state.apiEndpoint },
        getDownloadEndpoint: state => { return state.downloadEndpoint },
        getLoggedIn: state => { return state.loggedIn },
        getUser: state => { return state.user }
    },
    actions: {
        apiCall({ state, commit }, parameters) {
            return new Promise((resolve, reject) => {
                // expected parameters: destination, data, headers, callback
                let url = (parameters.destination.indexOf('.') > 0) ? parameters.destination : state.apiEndpoint + '?action=' + parameters.destination
                if (!('data' in parameters)) { parameters.data = {} }
                // copy parameters to a new object so adding system-wide values won't update the original object
                let postData = JSON.parse(JSON.stringify(parameters));
                // add system-wide variables to the new object
                postData.data.source = state.source
                let defaultHeaders = {
                    method: 'post',
                    body: JSON.stringify(postData.data),
                    credentials: 'include'
                }
                let headers = postData.headers || defaultHeaders
                fetch(url, headers).then(function(response) {
                    return response.json()
                }).then(obj => {
                    commit('UPDATE_LOADING', false)
                    if (obj.error) {
                        console.log('error!')
                        obj.error.status = 'error'
                        resolve(obj.error)
                    } else {
                        if (obj.status == 'login_required') {
                            document.location.replace('/login?return=' + document.location.pathname)
                        }
                        commit('UPDATE_STATE', obj)
                    }
                    resolve(obj)
                }).catch(error => {
                    console.log('error', error)
                    if (error == 'TypeError: Failed to fetch') {
                        // document.location.reload()
                    }
                    reject(error)
                })
            })
		}
    },
    modules: {
    }
})
