import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);

const globalMixins = {
    methods: {
        dateFromDateTime(datetime) {
			if (!datetime) return
            var d = new Date(datetime);
            return d.toLocaleDateString("en-US")
		},
        timeFromDateTime(datetime) {
			if (!datetime) return
            var d = new Date(datetime);
            return d.toLocaleString("en-US").split(', ').pop()
		},
        dateTimeToInput(datetime) {
			if (!datetime) return ''
            let dateTimeParts = datetime.split(' ')
            let timeParts = dateTimeParts[1].split(':')
            return dateTimeParts[0] + 'T' + timeParts[0] + ':' + timeParts[1]
        },
        caplitalizeWords(rawString) {
			if (!rawString) return
			return rawString.toLowerCase().replace(/_/g,' ').replace(/\b\w/g, l => l.toUpperCase())
		},
		isValidPhoneNumber(phoneNumber) {
			var pattern = new RegExp(/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/);
			return pattern.test(phoneNumber);
		},
		isValidEmailAddress(emailAddress) {
			var pattern = new RegExp(/^(("[\w-+\s]+")|([\w-+]+(?:\.[\w-+]+)*)|("[\w-+\s]+")([\w-+]+(?:\.[\w-+]+)*))(@((?:[\w-+]+\.)*\w[\w-+]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			return pattern.test(emailAddress);
		},
        copyObject(obj) {
            // limitations: doesn't work if object contains methods
            return JSON.parse(JSON.stringify(obj))
        },
        objectChanged(objA, objB) {
            // limitations: doesn't account for reordering or 1 == true on checkboxes
            return JSON.stringify(objA) != JSON.stringify(objB)
        },
        findKeyInObj(key, object) {
            var i = 0
            var found = {}
            while (i < Object.keys(object).length) {
                if (Object.keys(object)[i].indexOf(key) > -1) {
                    found[Object.keys(object)[i]] = object[Object.keys(object)[i]]
                }
                i++
            }
            return found
        },
        getFullName(obj) {
            let fullName = ''
            if (obj.first_name) {
                fullName += obj.first_name + ' '
            }
            if (obj.middle_name) {
                fullName += obj.middle_name + ' '
            }
            if (obj.last_name) {
                fullName += obj.last_name + ' '
            }
            if (obj.suffix) {
                fullName += obj.suffix
            }
            return fullName.trim()
        },
        getAddressLine(obj) {
            let addressLine = ''
            if (obj.address_1) {
                if (obj.address_2 || obj.city) {
                    addressLine += obj.address_1 + ', '
                } else {
                    addressLine += obj.address_1 + ' '
                }
            }
            if (obj.address_2) {
                if (obj.city) {
                    addressLine += obj.address_2 + ', '
                } else {
                    addressLine += obj.address_2 + ' '
                }
            }
            if (obj.city) {
                if (obj.state) {
                    addressLine += obj.city + ', '
                } else {
                    addressLine += obj.city + ' '
                }
            }
            if (obj.state) {
                addressLine += obj.state + ' '
            }
            if (obj.postal_code) {
                addressLine += obj.postal_code + ' '
            }
            return addressLine.trim()
        }
    }
}

const app = createApp(App)
app.mixin(globalMixins)
app.use(store)
app.use(router)
app.component("font-awesome-icon", FontAwesomeIcon)

app.mount('#app')
