<template>
	<div :style="'display: inline-block; width: ' + labelWidth">
		<font-awesome-icon icon="cog" class="spin" v-if="loading" /><span v-else>{{ localCaptializeWords(label) }}</span>
	</div>
</template>
<script>
export default {
	name: 'label-with-loading-icon',
	props: ['loading','label'],
	computed: {
		labelWidth() {
			return (this.label.length - 2) + 'em';
		}
	},
	methods: {
        localCaptializeWords(words) {
            return this.caplitalizeWords(words)
        }
	}
}
</script>
