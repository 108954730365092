<template>
    <AdminHeader :userName="getUser.user_name" :userTypeCode="getUser.user_type_code"/>
    <div class="main">
        <router-view/>
    </div>
    <AdminFooter :userName="getUser.user_name" :userTypeCode="getUser.user_type_code"/>
</template>

<script>
// @ is an alias to /src
import AdminHeader from '@/components/header.vue'
import AdminFooter from '@/components/footer.vue'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import '@/styles/_global.scss'
export default {
    name: 'App',
    components: {
        AdminHeader,
        AdminFooter
    },
    data() {
        return {
            loading: false,
			hamburgerOn: false,
            loggedIn: false
        }
    },
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'getLoggedIn',
            'getUser'
        ])
    },
    created() {
        this.apiCall({ destination: 'get_logged_in_user' })
    },
    methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ])
    }
}
</script>
