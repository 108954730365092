<template>
    <header @mouseleave="showNavPane = false">
        <div id="header-menu" v-if="showContent" @click="showNavPane = !showNavPane">
            <!-- <img src="@/assets/logo-lion-only-dark.png"> -->
            <font-awesome-icon icon="bars" />
            <span>MENU</span>
        </div>
        <div id="header-nav" v-if="showContent">
            <div class="user-name nav-item"><router-link to="/user-profile">{{ userName }} <font-awesome-icon icon="user" /></router-link></div>
            <div class="nav-item"><router-link to="/login?logout=1">Sign Out</router-link></div>
        </div>
        <div id="header-nav-pane" :class="{ active: showNavPane }">
            <div id="header-nav-mobile" v-if="showContent">
                <div class="user-name nav-item">Signed in: <router-link to="/user-profile">{{ userName }}</router-link></div>
                <div class="nav-item right"><router-link to="/login?logout=1">Sign Out</router-link></div>
            </div>
            <div class="header-nav-pane-content">
                <div class="sidebar-menu">
                    <router-link to="/dashboard" :class="{ active: isActive('/dashboard') }">Dashboard</router-link>
                    <router-link to="/user-profile" :class="{ active: isActive('/user-profile') }">Your User Profile</router-link>
                    <router-link to="/web-stats" :class="{ active: isActive('/web-stats') }">Web Stats</router-link>
                </div>
                <div class="main-menu">
                    <div>
                        <div class="menu-subhead">Contacts</div>
                        <router-link to="/contacts" :class="{ active: isActive('/contacts') }">Manage Contacts</router-link>
                        <router-link to="#contact-forms" class="dead">Contact Forms</router-link>
                        <router-link to="#contact-event-types" class="dead">Contact Event Types</router-link>
                        <router-link to="#interests" class="dead">Interests</router-link>
                        <router-link to="/mailchimp" :class="{ active: isActive('/mailchimp') }">Mailchimp</router-link>
                    </div>
                    <div>
                        <div class="menu-subhead">Events</div>
                        <router-link to="/registrations" :class="{ active: isActive('/registrations') }">Manage Registrations</router-link>
                        <router-link to="#events" class="dead">Manage Events</router-link>
                        <router-link to="#registration-methods" class="dead">Registration Methods</router-link>
                    </div>
                    <div>
                        <div class="menu-subhead">Donations</div>
                        <router-link to="/donations" :class="{ active: isActive('/donations') }">Manage Donations</router-link>
                        <router-link to="#donation-methods" class="dead">Donation Methods</router-link>
                        <router-link to="#payment-types" class="dead">Payment Types</router-link>
                    </div>
                    <div>
                        <div class="menu-subhead">Users</div>
                        <router-link to="/users" :class="{ active: isActive('/users') }">Manage Users</router-link>
                        <router-link to="#user-types" class="dead">User Types</router-link>
                        <router-link to="#permissions" class="dead">Permissions</router-link>
                    </div>
                    <div>
                        <div class="menu-subhead">Content</div>
                        <router-link to="/content" :class="{ active: isActive('/content') }">Content</router-link>
                        <router-link to="/videos" :class="{ active: isActive('/videos') }">Videos</router-link>
                        <router-link to="/images" :class="{ active: isActive('/images') }">Images</router-link>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'gap-header',
    props: {
        userName: String,
        userTypeCode: String
    },
    data() {
        return {
            showNavPane: false
        }
    },
    computed: {
        showContent() {
            return this.userName && this.$route.path.indexOf('login') < 0
        },
        activePath() {
            return this.$route.path
        }
    },
	watch: {
	    $route(){
	        this.showNavPane = false;
	    }
	},
    methods: {
        isActive(path) {
            return this.$route.path.indexOf(path) > -1

        }
    }
}
</script>
